<template>
    <div class="media" @click.self="this.$root.ClosePage()">
        <div class="content">
            <div class="sidebar">
                <span class="title">
                    Categorieën {{ PageIndex }}
                    <svg class="add" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g> <path d="M12 11V15M14 13H10M5 19H19C20.1046 19 21 18.1046 21 17V9C21 7.89543 20.1046 7 19 7H12.3284C11.798 7 11.2893 6.78929 10.9142 6.41421L10.0858 5.58579C9.71071 5.21071 9.20201 5 8.67157 5L5 5C3.89543 5 3 5.89543 3 7V17C3 18.1046 3.89543 19 5 19Z" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>
                </span>
                <div class="folders">
                    <div class="folder" @click="Active = false" :class="{ active: !Active }">
                        <svg viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg"><g><path d="M853.333333 256H469.333333l-85.333333-85.333333H170.666667c-46.933333 0-85.333333 38.4-85.333334 85.333333v170.666667h853.333334v-85.333334c0-46.933333-38.4-85.333333-85.333334-85.333333z" fill="#FFA000"></path><path d="M853.333333 256H170.666667c-46.933333 0-85.333333 38.4-85.333334 85.333333v426.666667c0 46.933333 38.4 85.333333 85.333334 85.333333h682.666666c46.933333 0 85.333333-38.4 85.333334-85.333333V341.333333c0-46.933333-38.4-85.333333-85.333334-85.333333z" fill="#FFCA28"></path></g></svg>
                        <span class="name">Alles</span>
                        <span class="counter">({{ Elements.length }})</span>
                    </div>
                    <div class="folder" v-for="(Category, i) in [... new Set (Elements.map(item => item.Category))].sort()" :key="i" @click="Active = Category" :class="{ active: Active == Category }">
                        <svg viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg"><g><path d="M853.333333 256H469.333333l-85.333333-85.333333H170.666667c-46.933333 0-85.333333 38.4-85.333334 85.333333v170.666667h853.333334v-85.333334c0-46.933333-38.4-85.333333-85.333334-85.333333z" fill="#FFA000"></path><path d="M853.333333 256H170.666667c-46.933333 0-85.333333 38.4-85.333334 85.333333v426.666667c0 46.933333 38.4 85.333333 85.333334 85.333333h682.666666c46.933333 0 85.333333-38.4 85.333334-85.333333V341.333333c0-46.933333-38.4-85.333333-85.333334-85.333333z" fill="#FFCA28"></path></g></svg>
                        <span class="name">{{ Category }}</span>
                        <span class="counter">({{ Elements.filter(item => item.Category == Category).length }})</span>
                    </div>
                </div>
            </div>
            <div class="items">
                <div class="header">
                    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g> <path d="M15.7955 15.8111L21 21M18 10.5C18 14.6421 14.6421 18 10.5 18C6.35786 18 3 14.6421 3 10.5C3 6.35786 6.35786 3 10.5 3C14.6421 3 18 6.35786 18 10.5Z" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>
                    <input v-model="Search" type="text" class="search" placeholder="Zoeken naar elementen">
                </div>
                <div class="list" :class="{ paddingBottom: Selected }">
                    <div class="item" v-for="(Item, i) in FilteredElements" :key="i" @click="Selected = Item" :class="{ selected: Selected == Item }">
                        <div class="image" :style="{ background: '#f5f5f5' }"></div>
                        <div class="information">
                            <span class="name">{{ Item.Name }}</span>
                            <span class="category">{{ Item.Description }}</span>
                        </div>
                    </div>
                </div>
                <div class="selected-row" v-if="Selected">
                    <span class="name">{{ Selected.Name }}</span>
                    <span class="insert" @click="InsertElement">Invoegen</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'PagePopup',

    props: ['PageIndex'],

    data () {
        return {
            Active: false,
            Search: '',
            Elements: [],
            Selected: false
        }
    },

    mounted () {
        this.GetElements()
    },

    methods: {
        GetElements () {
            this.$http.get(`https://api.wheels.nl/pages/elements`).then((response) => {
                if(response.data.elements) { this.Elements = response.data.elements } else { this.Elements = [] }
            })
        },

        InsertElement () {
            this.$root.ClosePage()
            this.$mitt.emit('ElementSelected', { Selected: this.Selected, Index: this.PageIndex })
        }
    },

    computed: {
        FilteredElements () {
            if(this.Active) {
                return this.Elements.filter(item => item.Category == this.Active)
            } else {
                return this.Elements
            }
        }
    }
}
</script>

<style scoped>
.media {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #33333380;
    cursor: pointer;
    z-index: 888;
}

.media .content {
    position: relative;
    display: flex;
    width: calc(100% - 250px);
    height: calc(100% - 250px);
    padding: 50px;
    background: #fff;
    border-radius: 10px;
    overflow: hidden;
    cursor: default;
}

.media .content .sidebar {
    display: flex;
    flex-direction: column;
    gap: 25px;
    width: 250px;
    padding: 0 25px 0 0;
    border-right: solid 1px #e5e5e5;
}

.media .content .sidebar .title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 20px;
    font-weight: 600;
}

.media .content .sidebar .title .add {
    width: 24px;
    height: 24px;
    stroke: #0171d7;
    cursor: pointer;
}

.media .content .sidebar .folders {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.media .content .sidebar .folders .folder {
    display: flex;
    align-items: center;
    gap: 5px;
    cursor: pointer;
}

.media .content .sidebar .folders .folder svg {
    width: 24px;
    height: 24px;
}

.media .content .sidebar .folders .folder .name {
    font-weight: 300;
}

.media .content .sidebar .folders .folder .counter {
    font-weight: 300;
    color: #a3a3a3;
    margin: 0 0 0 auto;
}

.media .content .sidebar .folders .folder.active .name, .media .content .sidebar .folders .folder.active .counter {
    font-weight: 600;
}

.media .content .items {
    width: calc(100% - 250px);
    padding: 0 0 0 25px;
}

.media .content .items .header {
    position: relative;
    display: flex;
    gap: 15px;
}

.media .content .items .header input {
    width: 100%;
    padding: 10px 10px 10px 50px;
    background: #f5f5f5;
    border-radius: 10px;
    border: 0;
    outline: 0;
}

.media .content .items .header select {
    padding: 10px;
    border: solid 1px #e0e0e0;
    border-radius: 10px;
    outline: 0;
}

.media .content .items .header svg {
    position: absolute;
    top: 12.5px;
    left: 15px;
    width: 20px;
    height: 20px;
}

.media .content .items .list {
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    gap: 25px;
    height: calc(100% - 25px);
    margin: 25px 0 0 0;
    padding: 0 0 25px 0;
    overflow: auto;
}

.media .content .items .list.paddingBottom {
    padding: 0 0 100px 0;
}

.media .content .items .list::-webkit-scrollbar {
    width: 3px;
}

.media .content .items .list::-webkit-scrollbar-track {
    background: transparent;
    margin: 0 0 25px 0;
}

.media .content .items .list::-webkit-scrollbar-thumb {
    background: transparent;
    border-radius: 5px;
    cursor: pointer;
}

.media .content .items .list:hover::-webkit-scrollbar-thumb {
    background: #b0b0b0;
}

.media .content .items .list::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.media .content .items .list .item {
    border: solid 1px #e5e5e5;
    border-radius: 10px;
    overflow: hidden;
    cursor: pointer;
}

.media .content .items .list .item.selected {
    border: solid 1px #0171d7;
}

.media .content .items .list .item .image {
    width: 100%;
    height: 150px;
    background-position: 50% !important;
    background-size: cover !important;
}

.media .content .items .list .item .information {
    padding: 15px;
}

.media .content .items .list .item .information .name {
    display: block;
    width: 100%;
    font-size: 18px;
    font-weight: 600;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.media .content .items .list .item .information .category {
    display: block;
    width: 100%;
    height: 50px;
    font-size: 16px;
    font-weight: 300;
}

.media .content .items .selected-row {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 75px;
    display: flex;
    align-items: center;
    padding: 0 55px;
    background: #fff;
    border-top: solid 1px #e5e5e5;
}

.media .content .items .selected-row .name {
    font-weight: 600;
}

.media .content .items .selected-row .insert {
    padding: 7.5px 25px;
    margin: 0 0 0 auto;
    color: #fff;
    background: #0371d7;
    font-weight: 600;
    border-radius: 10px;
    cursor: pointer;
}



@media screen and (min-width: 2500px) and (max-width: 9999px) {
    .media .content .items .list .item {
        width: calc((100% / 8) - 22px);
    }
}

@media screen and (min-width: 2100px) and (max-width: 2500px) {
    .media .content .items .list .item {
        width: calc((100% / 7) - 21.5px);
    }
}

@media screen and (min-width: 1800px) and (max-width: 2100px) {
    .media .content .items .list .item {
        width: calc((100% / 6) - 21px);
    }
}

@media screen and (min-width: 1500px) and (max-width: 1800px) {
    .media .content .items .list .item {
        width: calc((100% / 5) - 20.5px);
    }
}

@media screen and (min-width: 1200px) and (max-width: 1500px) {
    .media .content .items .list .item {
        width: calc((100% / 4) - 20px);
    }
}

@media screen and (min-width: 900px) and (max-width: 1200px) {
    .media .content .items .list .item {
        width: calc((100% / 3) - 19.5px);
    }
}

@media screen and (min-width: 600px) and (max-width: 900px) {
    .media .content .items .list .item {
        width: calc((100% / 2) - 19px);
    }
}

@media screen and (min-width: 0px) and (max-width: 600px) {
    .media .content .items .list .item {
        width: 100%;
    }
}
</style>